// import { Link } from "react-router-dom";

import Button from "../components/interactive/Button";

// import useCoreState from "../state/core";

const ButtonGallery = () => {
  // const [coreState] = useCoreState();

  return (
    <div className="h-full w-full flex bg-gray-900">
      <div className="text-6xl text-blue-600 mx-auto my-auto flex flex-col items-center space-y-12 p-5">
        <div>
          A <span className="text-purple-600">new</span> way to set up{" "}
          <span>deej</span> in three easy steps.
        </div>
        <div className="space-x-4">
          <Button primary>Hi there</Button>
          <Button primary xl>
            <div className="px-5">Do me!</div>
          </Button>
          <Button primary roundedFull>
            +
          </Button>
          <Button primary roundedFull xl>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="h-full w-full"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </div>
        <div className="space-x-4">
          <Button>Hi there</Button>

          <Button xl>
            <div className="px-5">Do me!</div>
          </Button>
          <Button roundedFull>+</Button>
          <Button roundedFull xl>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="h-full w-full p-4"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ButtonGallery;
