import { Route } from "react-router-dom";

import GlideSwitch from "./GlideSwitch";
import Welcome from "../../pages/Welcome";
import BasicSettings from "../../pages/BasicSettings";
import ButtonGallery from "pages/ButtonGallery";

const Content = () => {
  return (
    <GlideSwitch>
      <Route exact path="/" component={Welcome}></Route>
      <Route path="/basic-settings" component={BasicSettings}></Route>
      <Route path="/buttons" component={ButtonGallery}></Route>
    </GlideSwitch>
  );
};

export default Content;
