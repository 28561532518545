import { useLocalState } from "./state";

const forceNonDevelopmentMode = false;
const inDevelopmentMode = forceNonDevelopmentMode
  ? false
  : process.env.NODE_ENV === "development";

export interface FirstVisitState {
  chosenOperatingSystem: boolean;
  chosenPotType: boolean;
}

const defaultFirstVisitState: FirstVisitState = {
  chosenOperatingSystem: false,
  chosenPotType: false,
};

let developmentModeFirstVisitSingleton = { ...defaultFirstVisitState };

const useFirstVisitState = (): [
  FirstVisitState,
  (newValue: FirstVisitState) => void,
] => {
  const [
    realFirstVisitState,
    setRealFirstVisitState,
  ] = useLocalState<FirstVisitState>("firstVisit", defaultFirstVisitState);

  // in development, make every visit the first visit
  return [
    inDevelopmentMode
      ? developmentModeFirstVisitSingleton
      : realFirstVisitState,
    inDevelopmentMode
      ? (newValue: FirstVisitState) => {
          developmentModeFirstVisitSingleton = newValue;
        }
      : setRealFirstVisitState,
  ];
};

export default useFirstVisitState;
