import deejLogo from "../../img/deej-logo.svg";
import githubMark from "../../img/github-mark.png";

const Header = () => {
  return (
    <div className="flex justify-around px-3 w-full h-20 lg:h-32 bg-gray-800">
      <div className="h-full flex items-center opacity-80 hover:opacity-100 transition-opacity flex-shrink-0">
        {/* show deej:configure on desktop */}
        <div className="hidden md:flex h-full items-center">
          <div className="ml-3 bg-gradient-to-b p-0.5 from-deej-blue to-deej-purple rounded-lg shadow-glow-deej">
            <div className="bg-gray-800 pb-1.5 px-0.5 pt-0.5 rounded-md">
              <span className="tracking-wide font-display text-deej-blue-50 text-3xl select-none">
                deej
              </span>
            </div>
          </div>
          <div className="ml-0.5 font-mono text-3xl tracking-tighter text-deej-blue-50 select-none">
            :configure
          </div>
        </div>

        {/* show deej logo on mobile */}
        <div className="md:hidden rounded-full shadow-glow-deej">
          <img src={deejLogo} alt="deej" className="h-12 w-12 select-none" />
        </div>
      </div>

      <div className="hidden md:flex h-full text-white items-center mx-2 flex-shrink-0">
        <a
          href="https://github.com/omriharel/deej"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={githubMark}
            alt="Visit deej on GitHub"
            className="h-8 w-8 opacity-80 hover:opacity-100 transition-all select-none"
          ></img>
        </a>
      </div>
    </div>
  );
};

export default Header;
