import Button from "components/interactive/Button";
import { Link } from "react-router-dom";
import Step from "../components/content/Step";
import Steps, { StepsLayoutMode } from "../components/content/Steps";

const Welcome = () => {
  return (
    <div className="flex justify-center p-12 lg:p-24">
      <div className="text-deej-blue-500 flex flex-col space-y-24">
        <h1 className="text-5xl lg:text-6xl leading-tight">
          Welcome to a new, <br />
          <span className="text-deej-blue-600">beginner-friendly</span>
          <br /> way to set up{" "}
          <span className="italic text-deej-purple-500">deej</span>.
        </h1>

        <Steps
          layout={StepsLayoutMode.Vertical}
          className="self-center md:self-auto"
        >
          <Step number={1}>
            Tell us a bit about your <span className="italic">deej</span> setup
          </Step>
          <Step number={2}>Drag-and-drop apps to assign them</Step>
          <Step number={3}>
            Download your{" "}
            <span className="font-mono tracking-tighter text-deej-blue-500">
              config.yaml
            </span>{" "}
            file
          </Step>
        </Steps>
        <div className="self-end">
          <Button primary xl>
            <Link to="/basic-settings">
              <div className="px-8">{"Let's start!"}</div>
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
