import FixedHomeButton from "../components/layout/FixedHomeButton";
import MultiChoice from "../components/interactive/MultiChoice";

import useCoreState, { OperatingSystem, PotType } from "../state/core";
import useFirstVisitState from "../state/firstVisit";

import windowsLogo from "../img/windows-logo.svg";
import linuxLogo from "../img/linux-logo.svg";
import sliderIcon from "../img/slider.svg";
import knobsIcon from "../img/knobs.svg";

const BasicSettings = () => {
  const [coreState, setCoreState] = useCoreState();
  const [firstVisitState, setFirstVisitState] = useFirstVisitState();

  return (
    <>
      <FixedHomeButton />
      <div className="flex flex-col justify-around mx-auto my-12 w-10/12 lg:w-7/12">
        <div className="space-y-16 lg:w-4/5">
          <div className="w-1/2">
            <MultiChoice
              title="Choose your operating system"
              choicePropsArray={[
                {
                  title: "Windows",
                  value: OperatingSystem.windows,
                  image: windowsLogo,
                },
                {
                  title: "Linux",
                  value: OperatingSystem.linux,
                  image: linuxLogo,
                },
              ]}
              onChoice={(newValue: OperatingSystem) => {
                setCoreState({ ...coreState, os: newValue });
                setFirstVisitState({
                  ...firstVisitState,
                  chosenOperatingSystem: true,
                });
              }}
              currentValue={
                firstVisitState.chosenOperatingSystem ? coreState.os : undefined
              }
            />
          </div>

          <div className="w-4/5">
            <MultiChoice
              title="Which controls does your deej have?"
              choicePropsArray={[
                {
                  title: "Sliders",
                  value: PotType.sliders,
                  image: sliderIcon,
                  hintText:
                    'These move up and down.\nAlso known as "slide pots" or "faders"',
                },
                {
                  title: "Knobs",
                  value: PotType.knobs,
                  image: knobsIcon,
                  hintText:
                    'These rotate within a range.\nAlso known as "rotary pots"',
                },
              ]}
              onChoice={(newValue: PotType) => {
                setCoreState({ ...coreState, potType: newValue });
                setFirstVisitState({ ...firstVisitState, chosenPotType: true });
              }}
              currentValue={
                firstVisitState.chosenPotType ? coreState.potType : undefined
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicSettings;
