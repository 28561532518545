import { useLocalState } from "./state";

export enum PotType {
  sliders = "sliders",
  knobs = "knobs",
}

export enum OperatingSystem {
  windows = "windows",
  linux = "linux",
}

export interface SerialPort {
  com?: number;
  other?: string;
}

export interface CoreState {
  os: OperatingSystem;
  serialPort: SerialPort;
  potType: PotType;
  numSliders: number;
}

enum Keys {
  coreState = "core",
}

const defaultCoreState: CoreState = {
  os: OperatingSystem.windows,
  serialPort: { com: 4 },
  potType: PotType.sliders,
  numSliders: 5,
};

const useCoreState = () =>
  useLocalState<CoreState>(Keys.coreState, defaultCoreState);

export default useCoreState;
