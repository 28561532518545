import Choice, { ChoiceProps } from "./Choice";

interface MultiChoiceProps<T> {
  title: string;
  choicePropsArray: (Pick<ChoiceProps<T>, "value" | "title" | "image"> &
    Partial<Pick<ChoiceProps<T>, "hintText">>)[];
  onChoice: (choice: T) => void;
  currentValue?: T;
}

const MultiChoice = <T,>({
  title,
  choicePropsArray,
  onChoice,
  currentValue,
}: MultiChoiceProps<T>) => {
  return (
    <div className="flex flex-col space-y-8">
      <div className="text-3xl text-deej-blue-500 font-semibold">{title}</div>
      <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0 items-center">
        {choicePropsArray
          .map((choiceProps, idx) => {
            return [
              idx > 0 && (
                <div
                  className="uppercase text-deej-blue-300 text-lg opacity-70 select-none"
                  key={`choice-${idx}-sep`}
                >
                  or
                </div>
              ),
              <Choice
                key={`choice-${idx}`}
                onClick={onChoice}
                chosen={currentValue === choiceProps.value}
                {...choiceProps}
              ></Choice>,
            ];
          })
          .flat()}
      </div>
    </div>
  );
};

export default MultiChoice;
