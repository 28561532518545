import { Link } from "react-router-dom";

import Button from "components/interactive/Button";

const FixedHomeButton = () => {
  return (
    <div className="fixed m-10 bottom-0">
      <Link to="/">
        <Button roundedFull xl>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </Button>
      </Link>
    </div>
  );
};

export default FixedHomeButton;
