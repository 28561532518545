import { useState } from "react";

const localStoragePrefix = "deej-configure:user-state:";

const getFullItemKey = (key: string) => `${localStoragePrefix}${key}`;

// Acts like useState, but reads/writes to/from local storage.
// Only uses initialValue if a value isn't already persisted.
// Use clearLocalState or clearAllLocalState to reset.
export function useLocalState<T>(
  key: string,
  initialValue: T,
): [T, (newValue: T) => void] {
  const fullItemKey = getFullItemKey(key);
  const persistedItem = localStorage.getItem(fullItemKey);

  // if a persisted value exists, take it. else, default to initial
  const typedValue: T = persistedItem
    ? JSON.parse(persistedItem)
    : initialValue;

  const [reactState, setReactState] = useState<T>(typedValue);

  const persistentSetter = (newValue: T) => {
    setReactState(newValue);
    localStorage.setItem(fullItemKey, JSON.stringify(newValue));
  };

  return [reactState, persistentSetter];
}

export function clearLocalState(key: string) {
  const fullItemKey = getFullItemKey(key);
  localStorage.removeItem(fullItemKey);
}

export function clearAllLocalState() {
  localStorage.clear();
}
