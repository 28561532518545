import { ReactNodeArray, Fragment } from "react";

export enum StepsLayoutMode {
  Horizontal,
  Vertical,
}

interface StepsProps {
  children: ReactNodeArray;
  className?: string;
  layout: StepsLayoutMode;
}

const Steps = ({ children, className, layout }: StepsProps) => {
  const separator = (
    <div
      className={[
        "flex items-center",
        layout === StepsLayoutMode.Vertical ? "space-y-2" : "space-x-2",
        layout === StepsLayoutMode.Vertical ? "flex-col" : "flex-row",
      ].join(" ")}
    >
      <span className="bg-deej-blue-800 w-2 h-2 rounded-full"></span>
      <span className="bg-deej-blue-800 w-2 h-2 rounded-full"></span>
      <span className="bg-deej-blue-800 w-2 h-2 rounded-full"></span>
    </div>
  );

  return (
    <div
      className={[
        "flex items-center",
        layout === StepsLayoutMode.Vertical ? "space-y-6" : "space-x-6",
        layout === StepsLayoutMode.Vertical ? "flex-col" : "flex-row",
        className || "",
      ].join(" ")}
    >
      {children
        .map((child, idx) => {
          return idx === children.length - 1
            ? [<Fragment key={`steps-step-${idx}`}>{child}</Fragment>]
            : [
                <Fragment key={`steps-step-${idx}`}>{child}</Fragment>,
                <Fragment key={`steps-separator-${idx}`}>{separator}</Fragment>,
              ];
        })
        .flat()}
    </div>
  );
};

export default Steps;
