import { ReactNode } from "react";

interface StepProps {
  number: number;
  children: ReactNode[] | ReactNode | string;
}

const Step = ({ children, number }: StepProps) => {
  return (
    <div className="w-full h-auto md:h-32 rounded-3xl border-2 border-deej-blue-700 hover:border-deej-purple-500 transition-all flex items-center">
      <div className="m-6 rounded-full w-16 h-16 border-4 border-deej-blue-600 border-opacity-50 flex items-center justify-center">
        <div className="font-bold text-5xl text-deej-blue-600 h-full">
          {number}
        </div>
      </div>
      <div className="flex-1 h-full py-6 mr-6 flex items-center">
        <div className="text-3xl text-deej-blue-400">{children}</div>
      </div>
    </div>
  );
};

export default Step;
