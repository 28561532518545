import { ReactNode } from "react";

import { AnimatedSwitch, spring } from "react-router-transition";

interface GlideSwitchProps {
  children: ReactNode[];
}

const GlideSwitch = ({ children }: GlideSwitchProps) => {
  // calls react-motion's spring with a glide-like config
  const glide = (val: number) => {
    return spring(val, {
      stiffness: 125, // orig: 174
      damping: 20, // orig: 24
    });
  };

  return (
    <AnimatedSwitch
      atEnter={{ offset: -100 }}
      atLeave={{ offset: glide(100) }}
      atActive={{ offset: glide(0) }}
      mapStyles={(styles) => {
        return {
          transform: `translateX(${styles.offset}%)`,
        };
      }}
      className="animated-switch"
    >
      {children}
    </AnimatedSwitch>
  );
};

export default GlideSwitch;
