export interface ChoiceProps<T> {
  title: string;
  hintText?: string | string[];
  image: string;
  value: T;
  onClick: (newValue: T) => void;
  chosen: boolean;
}

const Choice = <T,>({
  title,
  hintText,
  image,
  value,
  onClick,
  chosen,
}: ChoiceProps<T>) => {
  return (
    <button
      className={[
        "w-full p-3",
        "flex items-center space-x-3 rounded-2xl",
        "bg-gray-300",
        "outline-none focus:outline-none select-none",
        chosen
          ? "border-4 shadow-glow-deej border-deej-purple-500"
          : "border-4 border-deej-purple-900",
        "hover:shadow-glow-deej",
        "transition-all",
      ].join(" ")}
      onClick={() => onClick(value)}
    >
      <div className="p-2 flex-shrink-0">
        <img src={image} alt={title} className="w-14 h-14" />
      </div>
      <div className="flex flex-grow flex-col text-left space-y-2 pr-2">
        <div className="flex flex-row">
          <div>
            <div className="text-2xl text-deej-blue-800 font-semibold">
              {title}
            </div>
            <div
              className={[
                "bg-deej-blue-800",
                "h-0.5 w-full transform transition-transform bg-opacity-50",
                chosen ? "scale-x-100" : "scale-x-0",
              ].join(" ")}
            />
          </div>
        </div>
        {hintText && (
          <div className="text-lg font-semibold text-deej-blue-800 text-opacity-70 whitespace-pre-line">
            {hintText}
          </div>
        )}
      </div>
    </button>
  );
};

export default Choice;
