import Content from "../components/layout/Content";
import Header from "../components/layout/Header";

const App = () => {
  return (
    <div className="flex w-screen h-screen bg-gray-900">
      <div className="flex flex-col w-full">
        <Header></Header>
        <Content></Content>
      </div>
    </div>
  );
};

export default App;
