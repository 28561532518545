import { ReactNode } from "react";

interface ButtonProps {
  children: ReactNode[] | ReactNode | string;
  onClick?: () => void;
  primary?: boolean;
  xl?: boolean;
  roundedFull?: boolean;
}

const Button = ({
  children,
  onClick,
  roundedFull,
  xl,
  primary,
}: ButtonProps) => {
  const paddingClasses = xl ? "px-1" : "px-3";

  /* postCSS: h-20 w-20 h-12 w-12 */
  const size = xl ? 20 : 12;
  const heightClass = `h-${size}`;
  const widthClass = roundedFull ? `w-${size}` : "";

  const roundingClasses = roundedFull ? "rounded-full" : "rounded-xl";
  const mutuallyExclusiveClasses = "items-center";

  return (
    <button
      className={[
        "inline-flex align-bottom p-0.5 justify-around",
        "shadow-md bg-gradient-to-t",
        primary ? paddingClasses : "",
        primary ? mutuallyExclusiveClasses : "",
        heightClass,
        widthClass,
        roundingClasses,
        xl ? "text-4xl" : "text-2xl",
        "tracking-wide font-semibold font-sans",
        primary ? "text-opacity-90" : "opacity-70",
        "text-gray-200 from-deej-purple-500 to-deej-blue-500",
        "hover:bg-deej-blue-100",
        "transition-all select-none hover:text-opacity-100",
        "outline-none focus:outline-none",
        "hover:ring-2 ring-deej-purple-500",
      ].join(" ")}
      onClick={onClick || (() => null)}
    >
      {primary ? (
        children
      ) : (
        <div
          className={[
            "bg-gray-900 bg-opacity-100 h-full w-full flex justify-around",
            mutuallyExclusiveClasses,
            paddingClasses,
            roundingClasses,
          ].join(" ")}
        >
          {children}
        </div>
      )}
    </button>
  );
};

export default Button;
